import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { List, X } from "phosphor-react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { MenuContext } from "../MenuContext";

const Header = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(MenuContext);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <header className="fixed w-full px-4 py-2 flex flex-col lg:flex-row justify-between lg:items-center bg-brand-tan z-10 shadow-lg shadow-slate-800/5">
      <div
        className={`flex justify-between ${
          isMenuOpen
            ? "border-dotted border-b pb-4 lg:pb-0 border-brand-red lg:border-b-0"
            : "border-b-0"
        }`}
      >
        <Link to="/" onClick={handleLogoClick}>
          <Logo />
        </Link>
        <button className="lg:hidden" onClick={handleMenuToggle}>
          {!isMenuOpen ? (
            <List className="text-brand-green" size={32} />
          ) : (
            <X className="text-brand-green" size={32} />
          )}
        </button>
      </div>
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } lg:flex lg:justify-end lg:flex-1`}
      >
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
