import React from "react";
import Carousel from "./Carousel";
import CTA from "./CTA";

const Hero = () => {
  const images = [
    {
      src: "/hero-images/buffalo-chicken-pizza-slice.jpg",
      alt: "Buffalo Chicken Pizza Slice",
    },
    { src: "/hero-images/baked-ziti.jpg", alt: "Baked Ziti" },
    {
      src: "/hero-images/grilled-calamari-salad.jpg",
      alt: "Grilled calamari salad",
    },
    {
      src: "/hero-images/mussels.jpg",
      alt: "Mussels with lemon white wine sauce",
    },
    {
      src: "/hero-images/margherita-pizza-slice.jpg",
      alt: "Margherita pizza slice",
    },
    {
      src: "/hero-images/arancini.jpg",
      alt: "Arancini (Rice Balls w/ Meat) with marinara sauce and topped with grated Parmigiano-Reggiano cheese",
    },
    {
      src: "/hero-images/burrata-caprese-salad.jpg",
      alt: "Tomatoes, burrata cheese, fresh basil, topped with extra virgin olive oil and finished with balsamic glaze",
    },
  ];
  return (
    <section className="pt-12">
      <Carousel images={images} />
      <CTA />
    </section>
  );
};

export default Hero;
