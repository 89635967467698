import React from "react";
import BayNews from "../../assets/brand-logos/BayNews.png";
import YouTube from "../../assets/brand-logos/youtube.svg";

const FeaturedIn = () => {
  return (
    <div className="flex flex-col bg-brand-tan py-12">
      <h2 className="flex font-typewriter text-md justify-center mb-4">
        Featured In
      </h2>
      <div className="flex text-justify px-4 sm:px-0 gap-8 max-w-2xl mx-auto text-xl font-typewriter">
        <a
          href="/BayNews.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={BayNews} alt="Bay News Logo" className="h-6" />
        </a>
        <a href="https://youtu.be/Z1Y9MgaDE40" target="_blank" rel="noreferrer">
          <img src={YouTube} alt="YouTube Logo" className="h-6" />
        </a>
      </div>
    </div>
  );
};

export default FeaturedIn;
